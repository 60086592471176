<div class="centered" [ngClass]="{'blur': blur}">
  <ion-text class="header">{{ title }}</ion-text>
  <div [style.backgroundColor]="currentSetting.outer" class="outer-circle">
    <div 
    [style.backgroundImage]="currentSetting.inner" 
    [ngClass]="{'grey-circle': blur}" 
    class="inner-circle d-flex align-items-center justify-content-center">
      <span class="num" [ngClass]="{'blur': blur}">{{ score }}</span>
    </div>
  </div>
  <ion-text class="description">{{ currentSetting.description }}</ion-text>
</div>
