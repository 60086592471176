import { Injectable } from '@angular/core';
import { DB_CONFIG } from '@app/app.firebase.config';
import { GameDifficultyEnum } from '@enums/DifficultyEnum';
import { Answer } from '@models/answer.model';
import { Question } from '@models/question.model';
import { Subject } from 'rxjs';
import * as _ from 'underscore';
import { AdminService } from './admin.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class Trivia2Service {

  difficulty = GameDifficultyEnum.Easy as GameDifficultyEnum;
  questions: Question[];
  answers: Answer[];
  allQuestions: Question[];
  questionIndex = 0;

  questionGotAnswered = new Subject();

  private loadTries = 5;
  private loadTimer = 1000;
  unviewed_questions;
  

  constructor(
    private adminService: AdminService,
    private authService: AuthenticationService
  ) {
    this.getAllQuestions();
    this.getAllAnswers();
    
    // for(let i = 0; i < 10; i++){
    //   this.debug();
    // }
    
  }

  handleError() {
    throw new Error('subscription error');
  }

  cleanse() {
    this.questions = [];
    this.questionIndex = 0;
  }


  user;
  async getUnviewedQuestions() {
    this.authService.userSubject.subscribe(async (user: any) => {
      if(user.unviewed_questions > 20){
        this.unviewed_questions = user.unviewed_questions;
      } else {
        const ids = this.allQuestions.map(a => {return a.id});
        await Promise.all(ids)
        // user has not played brain game yet
        user['unviewed_questions']= ids;
        this.unviewed_questions = user.unviewed_questions;
        this.authService.updateUser(user);
      }
      this.user = user
    })
  }

  async removeFromUnviewedQuestions(questions_to_remove, unviewed_questions_ids: any[]){
    const promises = questions_to_remove.map(checked => {
      const foundIndex = unviewed_questions_ids.findIndex(question => {return question === checked.id});
      if(foundIndex){
        unviewed_questions_ids.splice(foundIndex, 1);
      }
    });
    const test = await Promise.all(promises);
    this.user.unviewed_questions = unviewed_questions_ids;
    this.authService.updateUser(this.user);
    return unviewed_questions_ids;
  }

  async onlyUniqueQuestions(unviewed_questions, all_questions) {
    const promises = unviewed_questions.map(checked => {
      const test = all_questions.find(question => {return question.id === checked});
      if(test){
        return test;
      } else {
        throw new Error(`Couldn't find a question that matched the id`)
      }
      return 
    });
    await Promise.all(promises);
    return promises;
  }

  /**
   * gets every question from the database
   * needs to be called with service construction
   */
  getAllQuestions() {
    this.adminService.getEntries(DB_CONFIG.question_endpoint, 'type', null, false, false, 256).subscribe(val => {
      this.allQuestions = val;
      this.getUnviewedQuestions();
    });
  }

  async debug() {
    let no_group = []
    for(let i = 0; i < 200; i++){
      const test = await this.getEnoughQuestions();
      no_group = no_group.concat(test);
    }
    const grouped = _.groupBy(no_group, "id");
    const sorted = _.sortBy(grouped, 'length');
    console.log(`${sorted[0].length}, ${sorted[sorted.length-1].length}`)
    return;
    
  }

  /**
   * uses our hard coded 4 questions per round to find how many are needed.
   * @returns a promise with a resolution of qPerRound * rounds questions[]
   */
  async getEnoughQuestions() {

    // Check Initial Conditions
    const wait = (delay, ...args) => new Promise(resolve => setTimeout(resolve, delay, ...args));
    const tries = 5;
    const sleepTime = 1000;
    if(!this.allQuestions) {
      // if questions havent loaded, wait for it
      for(let i = 0; i < tries; i++){
        if(this.allQuestions){
          break;
        }
        await wait(sleepTime);
      }
      if(!this.allQuestions){
        throw new Error('Questions did not load')
      }
    }
    
    if(!this.answers){
      // if answers havent loaded, wait for it
      for(let i = 0; i < tries; i++){
        if(this.answers){
          break;
        }
        await wait(sleepTime);
      }
      if(!this.answers){
        throw new Error('Answers did not load')
      }
    }

    if(!this.unviewed_questions){
      // if unviewed questions havent loaded, wait for it
      for(let i = 0; i < tries; i++){
        if(this.unviewed_questions){
          break;
        }
        await wait(sleepTime);
      }
      if(!this.unviewed_questions){
        throw new Error('unviewed_questions did not load')
      }
    }


    
    // Questions Per Round * Rounds Number
    // 4 * 8 = 32 is default
    const qPerRound   = 5;
    const rounds      = 1;

    // limit to questions we havent seen yet in this rotation
    let questions = await this.onlyUniqueQuestions(this.unviewed_questions, this.allQuestions);

    // Shuffle the questions
    questions = _.shuffle(questions);
    // select first X questions, should be random at this point
    questions = questions.slice(0, (qPerRound * rounds));
     // add answers for each question
    const promises = questions.map(async (question: any) => {
      question['answers'] = [];
      question = await this.popQuestionWithAnswers(question);
    });
    await Promise.all(promises);
    if(questions.length < qPerRound * rounds){
      throw new Error(`not enough questions, only ${questions.length} here`)
    }
    this.questions = questions;
    await this.removeFromUnviewedQuestions(questions, this.unviewed_questions);
    return questions;
  }

  /**
   * given a questionNum, return that many formatted questions.
   * This has a used question array to give you different each time called.
   * @param questionNum amount of questions you want returned
   */
  getQuestions( questionNum: number ) {

  }

  /**
   * based on the difficulty, populates a question with answers
   * @param question question to populate with answers
   * @returns the same question object but with answers filled in
   */
  popQuestionWithAnswers( question: Question ) {
    
    return new Promise((resolve, reject) => {
      let filteredAnswers = [];
      Promise.resolve(
        _.shuffle(this.answers)
      ).then((res) => {
        this.answers = res;
        const correctAnswer = this.getCorrectAnswerForQuestion(1, question)
        filteredAnswers = [];
        // EASY
        if (this.difficulty === GameDifficultyEnum.Easy) {
          // Select 3 possible answers whose category does not match the question's answer-category.
          filteredAnswers = filteredAnswers.concat(this.getEasyAnswers(3, question, correctAnswer));
          filteredAnswers.push(correctAnswer);
          return filteredAnswers;
        
        // MEDIUM
        } else if (this.difficulty === GameDifficultyEnum.Medium) {
          // Select 1 possible answers whose category does not match the question's answer-category.
          filteredAnswers = filteredAnswers.concat(this.getEasyAnswers(1, question, correctAnswer));
    
          // Two(2) possible answers whose category matches the question's
          // answer-category and whose sub-category does not match the question's answer-sub-category.
          filteredAnswers = filteredAnswers.concat(this.getMediumAnswers(2, question, correctAnswer));
          filteredAnswers.push(correctAnswer);
          return filteredAnswers;

        // HARD
        } else if (this.difficulty === GameDifficultyEnum.Hard) {
          // Select 2 possible answers whose sub-category matches the question's answer-category,
          filteredAnswers = filteredAnswers.concat(this.getHardAnswers(2, question, correctAnswer));
    
          // One(1) possible answer whose category matches the question's answer-category
          // and whose sub-category does not match the question's answer-sub-category.
          filteredAnswers = filteredAnswers.concat(this.getMediumAnswers(1, question, correctAnswer));
          filteredAnswers.push(correctAnswer);
          return filteredAnswers;
        }
        
      }).then((res) => {
        // shuffle answers
        return _.shuffle(res);
      }).then((res) => {
        // res
        question.answers = res;
        resolve(question);
      }).catch(err => {
        reject(err);
      });
    });
  }

  /**
   * Get easy answers for a specific question where the answer category
   * does not match the question's answer-category.
   *
   * @param number amount of answers to return
   * @param question sepecific question
   * @returns an array of answers
   */
  getEasyAnswers(amount: number, question: Question, correctAnswer: Answer): Answer[] {
    const noCorrectAnswers = this.answers.filter(answer => answer.id !== correctAnswer.id);
    return noCorrectAnswers.filter(answer => answer.category !== question.correct_answer_category).slice(0, amount);
  }

  /**
   * Get medium level answers for a specific question where the answer category
   * matches the question's answer-category and whose sub-category does
   * not match the question's answer-sub-category.
   *
   * @param number amount of answers to return
   * @param question sepecific question
   * @returns an array of answers
   */
  getMediumAnswers(amount: number, question: Question, correctAnswer: Answer): Answer[] {
    const noCorrectAnswers = this.answers.filter(answer => answer.id !== correctAnswer.id);
    return noCorrectAnswers.filter(answer => (
      (answer.category === question.correct_answer_category) &&
      (!question.correct_answer_sub_category.includes(answer.sub_category))
    )).slice(0, amount);
  }

  /**
   * Get hard level answers for a specific question  whose category matches the question's answer-category
   * and whose sub-category does match the question's answer-sub-category.
   *
   * @param number amount of answers to return
   * @param question sepecific question
   * @returns an array of answers
   */
  getHardAnswers(amount: number, question: Question, correctAnswer: Answer): Answer[] {
    const noCorrectAnswers = this.answers.filter(answer => answer.id !== correctAnswer.id);
    const matchCategoryAnswers = noCorrectAnswers.filter(answer => answer.category === question.correct_answer_category);
    return matchCategoryAnswers.filter(answer => answer.sub_category === question.correct_answer_sub_category).slice(0, amount);
  }

  /**
   * Get correct answer for a specific question
   *
   * @param number amount of answers to return
   * @param question sepecific question
   * @returns an of Answer object
   */
   getCorrectAnswerForQuestion(amount: number, question: Question): Answer {
    this.answers = _.shuffle(this.answers);
    
    let newAnswer: any = {};
    this.answers.forEach((answer: Answer) => {
      if (question.answer_id === answer.id) {
        // Clone object to prevent referencing to original object
        newAnswer =  Object.assign({}, answer);
      }
    });

    newAnswer.is_correct = true;
    return newAnswer;
  }

  /**
   * auto called after x questions have been answered, can be called manually
   */
  storeResponse() {
    // this.bgController.storeResponse();
  }

  /**
   * handles all responses for when a question gets answered,
   * whether it is right or wrong
   * @param answer answer given by the user
   * @param question question answered by the user
   */
  questionAnswered(answer: Answer, question: Question) {
    this.questionGotAnswered.next({answer, question})
  }

  getAllAnswers() {
    this.adminService.getEntries(DB_CONFIG.answer_endpoint, 'details', null, false, false, 1000).subscribe(val => {
      this.answers = val;
    });
  }
}
