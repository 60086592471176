
<div class="custom-selector">
  <select  name="date-select" (change)="tabClicked($event);flipArrowBack()" (select)="flipArrowBack()" (focus)="flipArrow()" (blur)="flipArrowBack()">
    <option *ngFor="let assessment of Assessments; let i = index" [value]="i" [selected]="i === Assessments.length-1">{{assessment.display_date}}</option>
  </select>
  <span class="custom-arrow" [ngStyle]="{'transform': transform }"></span>
</div>

<!-- <ion-content> -->
  <ion-grid class='pt-0' >
    <ion-row>
      <ion-col class='pt-0'>
        <div class="centered" #contentToPrint>

          <!-- Baseline Section -->
          <ion-card style="padding: 20px; box-shadow: none!important">
            <div *ngIf="showBaseline">
              <h1 class="header-title">Baseline Score Summary</h1>
              <app-divider></app-divider>

              <ion-row>
                <ion-col size='6'>
                  <app-brain-charge-meter [score]="baselineOverall"></app-brain-charge-meter>
                </ion-col>
                <ion-col size='6'>
                  <div class="summary-container">
                    <span class="baseline-summary">
                      Your initial metrics include only data from the Assessment.
                    </span>
                    <app-current-score-summary [overallScore]="baselineOverall"></app-current-score-summary>
                    <span *ngIf="summarySorted.ideal.length > 0 && !free_trial" class="baseline-text">Your
                      <span *ngFor="let name of summarySorted.ideal; let i = index" class="font-weight-bold">
                        {{name}}<span *ngIf="i !== summarySorted.ideal.length - 1">,</span>
                      </span>
                      scores were at ceiling.
                    </span>
                    <span *ngIf="summarySorted.below.length > 0" class="baseline-text">Ideal targets for training were
                      <span *ngFor="let name of summarySorted.below; let i = index" class="font-weight-bold">
                        {{name}}<span *ngIf="i !== summarySorted.below.length - 1">,</span>
                      </span>.
                    </span>
                    <span *ngIf="!free_trial" class="baseline-text">
                      This report will discuss each sub-score in detail.
                    </span>
                    <span *ngIf="free_trial" class="baseline-text">
                      <b>We offer a more detailed report once paid for.</b>
                    </span>
                  </div>
                </ion-col>
              </ion-row>

              <app-divider></app-divider>

              <ion-row class="spacing">
                <ion-col class="centered" size='2.4' *ngFor="let scoreFactor of scoreFactors">
                  <ion-card class="tile" (click)='showScoreFactor(scoreFactor)'>
                    <app-tile [blur]="free_trial" title={{scoreFactor.title}} score={{scoreFactor.score}}></app-tile>
                  </ion-card>
                </ion-col>
              </ion-row>

              <ng-template *ngIf="!free_trial">
                <ion-row  *ngFor="let scoreFactor of scoreFactors; let i = index" class="column">
                  <app-trajectory-comparison [scoreFactor]="scoreFactor" [allData]="scoreFactor.allData"></app-trajectory-comparison>
                </ion-row>
              </ng-template>
              

              <div class="background-padded align-center">
                <app-button text="Start Training" (click)="startTraining()"></app-button>
              </div>

            </div>

            <!-- Overall Progress Summary Section -->
            <div *ngIf="showOverall">
              <h1 class="header-title">Current Score Summary</h1>
              <app-divider></app-divider>

              <ion-row>
                <ion-col size='6'>
                  <app-brain-charge-meter [score]="baselinePlusOverall"></app-brain-charge-meter>
                </ion-col>
                <ion-col size='6'>
                  <div class="summary-container">
                    <span class="baseline-summary">
                      Your current metrics include data from both the Assessment and your use of the Brain Charge
                      Program.
                    </span>
                    <app-current-score-summary></app-current-score-summary>
                    <span *ngIf="summarySorted.ideal.length > 0" class="baseline-text">Your
                      <span *ngFor="let name of summarySorted.ideal; let i = index" class="font-weight-bold">
                        {{name}}<span *ngIf="i !== summarySorted.ideal.length - 1">,</span>
                      </span>
                      scores were at ceiling.
                    </span>
                    <span *ngIf="summarySorted.below.length > 0" class="baseline-text">Ideal targets for training were
                      <span *ngFor="let name of summarySorted.below; let i = index" class="font-weight-bold">
                        {{name}}<span *ngIf="i !== summarySorted.below.length - 1">,</span>
                      </span>.
                    </span>
                    <span class="baseline-text">
                      The following sections will discuss your progress overall and on each sub-score detail.
                    </span>
                  </div>
                </ion-col>
              </ion-row>

              <app-divider></app-divider>

              <ion-row class="spacing">
                <ion-col class="centered" size='2.4' *ngFor="let scoreFactor of scoreFactors">
                  <ion-card class="tile" (click)='showScoreFactor(scoreFactor)'>
                    <app-tile title={{scoreFactor.title}} score={{scoreFactor.score}}></app-tile>
                  </ion-card>
                </ion-col>
              </ion-row>

              <!-- Overall Progress Summary -->
              <h1 class="header-title">Overall Progress Summary</h1>
              <app-divider></app-divider>
              <ion-row class="improvement-container">
                <div class="improvement-row improvement-text"
                  [ngStyle]='{ "background-color": trajectoryColor, "border": trajectoryBorder}'>{{trajectoryTitle}}
                  <p class="improvement-percent">{{improvement}}%</p>
                </div>
              </ion-row>
              <ion-row class="score-chart p-0">
                <ion-col size="11" class="p-0">
                  <app-bars-over-time [datas]="overallData"></app-bars-over-time>
                </ion-col>
              </ion-row>

              <ion-row class="summary-para">
                <p class="summary-para-container">Your current metrics show an overall 
                  <span *ngIf="improvement > 0">improvement</span><span *ngIf="improvement < 0">decline</span> from
                  <b>{{previousScore}}</b> to <b>{{overallScore}}</b> out of 10, or <b>{{improvement}}%</b>.
                  <span>{{metricText}}</span>
                </p>
              </ion-row>

              <!-- NEXT FOCUS AND BIGGEST IMPROVEMENT SECTION FOR OVERALL PROGRESS-->

              <ion-row class="focus-container">
                <ion-col size="7" class="next-focus">
                  <div class="summary-background">
                    <h2 class="font-weight-bold">Next Focus</h2>
                    <div class="next-img">
                      <span *ngFor="let image of imgSorted.below; let i = index">
                        <img src="{{image.img}}" class="summary-img">
                        <p>{{image.title}}</p>
                      </span>
                    </div>
                  </div>
                </ion-col>

                <ion-col size="5" class="improvement">
                  <div class="summary-background">
                    <h2 class="font-weight-bold">Biggest Improvement</h2>
                    <img src="{{imgSorted.biggestImprovement.img}}" class="summary-img">
                    <p>{{imgSorted.biggestImprovement.title}}</p>
                  </div>
                </ion-col>
              </ion-row>

              <h1 class="header-title">Compliance</h1>
              <ion-row>
                <ion-col size="8">
                  <div style="width: 90%; height: 400px; margin: auto">
                    <app-assessment-score-chart [assessments]="Assessments"></app-assessment-score-chart>
                    <div style="border: dashed 2px black"></div>
                    <app-compliance-chart [assessments]="Assessments"></app-compliance-chart>
                  </div>
                </ion-col>
                <app-chart-legend style="display: flex; flex-direction: column; justify-content: center"></app-chart-legend>
              </ion-row>

              <!-- SCORE FACTOR PROGRESS DETAILS -->
              <ion-row *ngFor="let scoreFactor of scoreFactors" class="column">
                <h1>{{scoreFactor.title}} Progress Details</h1>
                <app-divider></app-divider>
                <app-progress-detail [AssIndex]="AssIndex" [scoreFactor]="scoreFactor" [allData]="scoreFactor.allData"></app-progress-detail>
              </ion-row>

              <!-- NEXT STEP -->

              <h1 class="header-title"> Next Steps</h1>

              <ion-row class="summary-para">
                <p class="steps-para-container">Your next phase of Brain Charge will feature
                  recommendations to improve your Speed, Strategy, and Focus. You will also notice
                  a decrease in the amount of training recommended for you each day.
                </p>
              </ion-row>

              <ion-row class="focus-container">
                <ion-col size="7" class="next-focus">
                  <div class="summary-background">
                    <h2 class="font-weight-bold">Next Focus</h2>
                    <div class="next-img">
                      <span *ngFor="let image of imgSorted.below; let i = index">
                        <img src="{{image.img}}" class="summary-img">
                        <p>{{image.title}}</p>
                      </span>
                    </div>
                  </div>
                </ion-col>

                <ion-col size="5" class="improvement">
                  <div class="summary-background">
                    <h2 class="average-time">Avg Time Per Week</h2>
                    <h1 class="minutes">196</h1>
                    <h2>minutes</h2>
                  </div>
                </ion-col>
              </ion-row>

              <div class="background-padded align-center mb-5">
                <app-button text="Start Training" (click)="startTraining()"></app-button>
              </div>

            </div>
          </ion-card>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

<!-- </ion-content> -->
<button (click)="printContent()">Print this page</button>