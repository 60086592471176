import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'underscore';
import { Utilities } from '@common/utilities';
import { Assessment } from '@models/assessment.model';
import { NavigationEnum } from '@enums/NavigationEnum';
import { AssessmentService } from '@services/assessment.service';
import {
  SwiperConfigInterface,
  SwiperComponent
} from 'ngx-swiper-wrapper';
import { AuthenticationService } from '@services/authentication.service';
import { DisclaimerPage } from '@common/components/modal/disclaimer/disclaimer.page';
import { take } from 'rxjs/operators';
import { HomeService } from '@services/home.service';
import { AdminService } from '@services/admin.service';
import { DB_CONFIG } from '@app/app.firebase.config';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss'],
})
export class SetupComponent extends Utilities implements OnInit {

  @ViewChild(SwiperComponent, { static: true }) swiper?: SwiperComponent;
  swiperConfig: SwiperConfigInterface = {};
  swiperIndex = 0;
  assessment = {} as Assessment;
  userId: string;
  assessmentId: string;
  errorMessage: string;
  step: string;

  moods = [
    {
      enabled: false,
      imageUrl: '../../../../assets/images/mood/verysad.png',
      name: 'vsad',
      value: 0
    },
    {
      enabled: false,
      imageUrl: '../../../../assets/images/mood/sad.png',
      name: 'sad',
      value: 1
    },
    {
      enabled: false,
      imageUrl: '../../../../assets/images/mood/meh.png',
      name: 'meh',
      value: 2
    },
    {
      enabled: false,
      imageUrl: '../../../../assets/images/mood/smile.png',
      name: 'happy',
      value: 3
    },
    {
      enabled: false,
      imageUrl: '../../../../assets/images/mood/veryhappy.png',
      name: 'vhappy',
      value: 4
    },
  ];

  prevEnabledMood = -1;
  cobranding: any;

  constructor(
    public navCtrl: NavController,
    public modalController: ModalController,
    private formBuilder: FormBuilder,
    private assessmentService: AssessmentService,
    private modalCtrl: ModalController,
    private authService: AuthenticationService,
    private homeService: HomeService,
    private adminService: AdminService
  ) {
    super(navCtrl);
  }

  ngOnInit() {
    this.getAuthUser();
    this.configureStyles();
  }

  ngAfterViewInit() {
    // swiper configuration
    this.swiperConfig = {
      slidesPerView: 1,
      loop: false,
      effect: 'slide',
      observer: true,
      observeParents: true,
    };
  }

  determineNewOrReturn(id?) {
    if(!id){
      if(this.userId){
        id = this.userId;
      } else {
        throw new Error('no id found')
      }
    }
    // determine whether to do a new assessment or return to an unfinished one
    this.assessmentService.getAssessments(id).then(ass => {
      const asses = ass.assessments;
      // if most recent assessment is not complete, allow to restart from last quiz
      if(!(asses[asses.length - 1].is_complete)) {
        if(!asses[asses.length - 1].results_data) {console.log('there was a previous assessment but no results'); return;}
        this.displayNewOrReturn(asses[asses.length - 1].results_data.length);
      }
    })
  }

  displayNewOrReturn(quizNo: number) {
    let quizUrl;
    switch(quizNo) {
      case 0: quizUrl = 'one';break;
      case 1: quizUrl = 'two';break;
      case 2: quizUrl = 'three';break;
      case 3: throw Error('quiz must be finished it has three rounds of data');
      default: throw Error('improper quiz number');
    }
    window.alert(`going to quiz ${quizNo+1}`);
    
    this.assessmentService.setup_done.next(true);
    this.assessmentService.nextQuiz(quizNo+1);
    return;
    // uncomment to be able to choose a new assessment
    // this.presentModal(ReturningComponent, 'returning-modal', null, false)
    // this.assessmentService.returningModalClosed.subscribe(val => {
    //   if(val === 'return') {
    //     this.navigateToPath(`assessment/quiz-${quizUrl}/`, NavigationEnum.Forward);
    //   }
    //   this.closeModal();
    // });
  }

  /**
   * Gets the authenticated user from the AuthenticationService.
   */
  getAuthUser(): void {
    this.authService.userSubject.pipe(take(1)).subscribe(async (user) => {
      if (user) {
        this.assessment.user_id = user.id;
        this.userId = user.id;
        if(!user.agreedTerms) {
          // present terms modal
          this.openDisclaimer(user);
        } else {
          // user.id = '000TESTING';
          this.determineNewOrReturn(user.id);

          // first copy assessment
          // const ass = await this.adminService.getEntryById('LINDANEWSUMBACKUP', DB_CONFIG.assessment_endpoint);
          // const new_ass = Object.assign(new Object,ass);
          // // // second save under new assessment 4 backup and use
          // new_ass.id = '000TESTING';
          // await this.adminService.saveEntryById(new_ass, DB_CONFIG.assessment_endpoint);
          // this.determineNewOrReturn('000TESTING');
        }
      } else {
        throw new Error('error getting user, reload page');
      }
    })
  }

  /**
   * Gets the cobranding styles from the HomeService.
   */
  configureStyles(): void {
    this.homeService.getCobranding().subscribe(cobrand => {
      this.cobranding = cobrand;
    });;
  }
  
  /**
   * Creates the form controls used to validate the mood.
   * @returns The FormGroup to track the value and validity state of the mood form.
   */
  createFormControls(): FormGroup {
    return this.formBuilder.group({
      mood: ['', [Validators.required]]
    });
  }

  /**
   * Sets the mood based on what the user clicked on.
   * @param index The index of the mood selected in the moods array.
   */
  moodClicked(index: number): void {
    if (this.prevEnabledMood === index && this.moods[this.prevEnabledMood].enabled) {
      this.moods[index].enabled = false;
      return;
    }
    else if (this.prevEnabledMood !== -1) {
      this.moods[this.prevEnabledMood].enabled = false;
    }

    this.moods[index].enabled = true;
    this.prevEnabledMood = index;
    this.assessment.mood = this.moods[this.prevEnabledMood].name;
  }

  /**
   * Determines the next step based on the swiper index.
   */
  moveToNextStep(): void {
    
    if (this.swiperIndex === 0) {
      this.swiper.directiveRef.nextSlide();
      if (!this.assessment.mood) {
        this.errorMessage = 'Please select a mood';
        return;
      }
      this.saveMood();
    } else {      
      this.startQuiz();
    }
  }

  /**
   * Saves the mood to the AssessmentService and moves to the next screen.
   */
  saveMood(): void {
    this.swiper.directiveRef.nextSlide();
    this.assessmentService.newAssessment(this.assessment.mood).then(() => {
      this.swiper.directiveRef.nextSlide();
    }).catch(err => {
      throw new Error('error saving assessment, please reload');
    })
  }

  /**
   * Sets the swiper index from the current question 
   * to determine when the end has been reached.
   */
  onIndexChange(index: number): void {
    this.swiperIndex = index;
  }

  /**
   * Navigates forward to the first quiz.
   */
  startQuiz(): void {
    this.assessmentService.setup_done.next(true);
  }

  /**
   * Presents the Disclaimer modal.
   * @param user The current user.
   * @returns A promise that the modal has been presented.
   */
  async openDisclaimer(user: any): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: DisclaimerPage, // opens the disclaimer page inside the modal
      backdropDismiss: false, // prevents users from clicking outside container to dismiss
      componentProps: {
        user: user // passes userID to the modal
      }
    });
    return await modal.present();
  }
}
