import { Injectable } from '@angular/core';
import { IUser } from '@common/interface/user.interface';
import { map } from 'rxjs/operators';
import { DB_CONFIG } from '../app.firebase.config';
import { RoleEnum } from '@common/enums/RoleEnum';
import { Utilities } from '@common/utilities';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AuthenticationService } from './authentication.service';
import moment from 'moment';
import { AdminService } from './admin.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  private usersRef: AngularFirestoreCollection<IUser>;

  constructor(
    private afs: AngularFirestore,
    private authService: AuthenticationService,
    private adminService: AdminService
  ) { this.init() }

  init() {
    this.authService.userSubject.subscribe(user => {
      this.user = user;
    })
  }

  /**
   * Not a subscription
   */
  getUser(): any {
    return this.user;
  }

  /** Get Users by Subscriber Id from firestore */
  getUsersBySubscriber(subscriberId: string) {
    this.usersRef = this.afs.collection<IUser>(
      DB_CONFIG.user_endpoint,
      ref => ref.where('subscriber_id', '==', subscriberId).orderBy('name')
    );

    return this.usersRef.snapshotChanges()
      .pipe(
        map(actions => {
          const data = Utilities.mapActions(actions);
          return data;
        })
      );
  }

   /** Get All Users */
   getUsers() {
    this.usersRef = this.afs.collection<IUser>(
      DB_CONFIG.user_endpoint,
      ref => ref.orderBy('name')
    );

    return this.usersRef.snapshotChanges()
      .pipe(
        map(actions => {
          const data = Utilities.mapActions(actions);
          return data;
        })
      );
  }

  getAllPlayers() {
    this.usersRef = this.afs.collection<IUser>(
      DB_CONFIG.user_endpoint,
      ref => ref.where('role', '==', RoleEnum.Client).orderBy('name')
    );

    return this.getUserSnapshot();
  }

  /**
   * gets the users data
   */
  getUserSnapshot() {
    return this.usersRef.snapshotChanges()
      .pipe(
        map(actions => {
          const data = Utilities.mapActions(actions);
          return data;
        })
      );
  }

  user;
  isUserAssessmentOnly() {
    return this.user.assessment_only;
  }

  isUserExpired() {
    const user_date = moment(this.user.expiration_date).startOf('d');
    const diff = moment().endOf('d').diff(user_date, 'd');
    if(diff > -30){
      const email = {
        // to: this.user.email,
        to: ['clarkeyt624@gmail.com', 'ellen@brainevolved.com'],
        message: {
          html: `${this.user.name} has logged in and is expiring soon`,
          subject: `${this.user.email} is expiring within a month, reach out to them`
        }
      }
      this.adminService.saveEntry(email, DB_CONFIG.email_endpoint);
    }
    return diff > 0;
  }
}
