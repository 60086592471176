import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
})
export class TileComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() score: any;
  @Input() blur = false as boolean;

  scoreSettings = [
    // red (1 - 2)
    {
      outer: 'rgba(186, 65, 58, 0.7)',
      inner: 'linear-gradient(154deg, #e79762 9%, #ba413a 70%)',
      description: 'Challenge'
    },
    // orange (3 - 4)
    {
      outer: 'rgba(231, 151, 98, 0.7)',
      inner: 'linear-gradient(156deg, #f4ce73 9%, #e79762 66%)',
      description: 'Fair'
    },
    // pear (5 - 6)
    {
      outer: 'rgba(244, 206, 115, 0.7)',
      inner: 'linear-gradient(154deg, #9cc84a 10%, #f4ce73 75%)',
      description: 'Good'
    },
    // green (7 - 8)
    {
      outer: 'rgba(156, 200, 74, 0.7)',
      inner: 'linear-gradient(163deg, #628846 7%, #9cc84a 64%)',
      description: 'Very good'
    },
    // dark green (9 - 10)
    {
      outer: 'rgba(98, 136, 70, 0.7)',
      inner: 'linear-gradient(152deg, #254210 10%, #628846 70%)',
      description: 'Excellent'
    }
  ];

  currentSetting = {
    outer: '',
    inner: '',
    description: ''
  };

  constructor() { }

  ngOnInit() {
    this.setValues();
  }

  ngOnChanges() {
    this.setValues();
  }

  /**
   * Sets the outer circle, inner circle, and description based on the score input
   */
  setValues(): void {
    if(this.score > 10) {this.score = 10;}
    if(this.score === "10.0") {this.score = 10;}
    this.currentSetting = this.scoreSettings[this.score > 0 ? Math.ceil(this.score / 2) - 1 : 0];
  }
}
